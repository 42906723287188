<template>
  <div>
    <!-- 条件 -->
    <el-row>
      <el-form :inline="true" :model="params" class="demo-form-inline">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="params.examStatus"
            @change="sendAsk"
            disabled
            placeholder="草稿"
          >
            <el-option label="草稿" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试类型" class="formItemBoxStyle">
          <el-select
            v-model="params.makeup"
            @change="sendAsk"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="补考" :value="true"></el-option>
            <el-option label="正式考试" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="params.examName"
            @change="handleCurrentChange(1)"
          ></el-input>
        </el-form-item>
        <el-form-item label="考试编码" class="formItemBoxStyle">
          <el-input
            v-model="params.examCode"
            @change="handleCurrentChange(1)"
          ></el-input>
        </el-form-item>
        <el-button v-throttle type="primary" @click="sendAsk">查询</el-button>
        <el-button v-throttle @click="onEmpty">重置</el-button>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-row>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loadingTable"
      >
        <el-table-column
          align="center"
          prop="examName"
          label="考试名称"
        ></el-table-column>
        <el-table-column align="center" label="考试状态" width="100">
          <template slot-scope="scope">{{
            scope.row.examStatus | examStatusFilter
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="examCode"
          label="考试编码"
          width="130"
        ></el-table-column>
        <el-table-column align="center" prop="makeup" label="考试类型">
          <template slot-scope="scope">{{
            scope.row.makeup ? '补考' : '正式考试'
          }}</template>
        </el-table-column>
        <el-table-column align="center" label="考试科目" width="160">
          <template slot-scope="scope">{{
            scope.row.subjectTypeList | subjectTypeFilter
          }}</template>
        </el-table-column>
        <el-table-column align="center" label="开始时间" width="160">
          <template slot-scope="scope">{{
            scope.row.examStartTime | timeDayFilter
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arrangeModel"
          label="排考方式"
          width="160"
        >
          <template slot-scope="scope">{{
            scope.row.arrangeModel | arrangeModelFilter
          }}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="340">
          <template slot-scope="scope">
            <el-button
              v-throttle
              type="primary"
              size="mini"
              @click="checkBtn(scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              type="success"
              size="mini"
              @click="editBtn(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button
              v-throttle
              v-if="!scope.row.makeup"
              type="primary"
              size="mini"
              @click="copyBtn(scope.row.examId)"
              >复制</el-button
            > -->
            <el-button
              v-throttle
              type="danger"
              size="mini"
              @click="deleteBtn(scope.row.examId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { examInfo, deleteExamInfo, copyExamInfo } from '@/api/exam/exam.js'
import dayjs from 'dayjs'
import { Loading } from 'element-ui'
export default {
  // 排考
  data() {
    return {
      // 条件
      params: {
        arrangeModel: '',
        examStatus: 1,
        subjectType: '',
        makeup: 0,
      },
      tableData: [
        // {
        // arrangeModel: 0,
        // examCode: "",
        // examId: 0,
        // examName: "",
        // examStartTime: 0,
        // examStatus: 0,
        // markType: 0,
        // subjectType: []
        // }
      ],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },

      loadingTable: false,
    }
  },
  methods: {
    deleteBtn(val) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteExamInfo(val).then((res) => {
            console.log(' 删除', val, res)
            if (res.success) {
              this.sendAsk()
              this.$emit('flushedFunc2')
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    copyBtn(val) {
      this.$confirm('确认复制?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let loadingInstance = Loading.service({
            text: '复制中',
          })
          copyExamInfo(val).then((res) => {
            loadingInstance.close()
            if (res.success) {
              this.sendAsk()
              this.$message({
                type: 'success',
                message: '复制成功!',
              })
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消复制',
          })
        })
    },
    editBtn(val) {
      console.log('val', val)
      if (val.makeup) {
        this.$router.push({
          path: '/exam/examAdd',
          query: {
            mainExamId: val.mainExamId,
            parentExamId: val.parentExamId,
            examId: val.examId,
          },
        })
      } else {
        this.$router.push({ path: '/exam/examAdd?examId=' + val.examId })
      }
    },
    checkBtn(val) {
      console.log(' 查看', val)
      this.$router.push({
        path: '/exam/examCheck',
        query: {
          examId: val.examId,
          makeup: val.makeup,
        },
      })
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.sendAsk()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.sendAsk()
    },
    onEmpty() {
      console.log('清空')
      this.params = {
        examStatus: 1,
      }
      this.sendAsk()
    },
    // 请求
    sendAsk() {
      for (let key in this.params) {
        if (
          this.params[key] === 0 ||
          this.params[key] === '' ||
          this.params[key] === undefined
        ) {
          delete this.params[key]
        }
      }
      this.loadingTable = true
      examInfo(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.params,
        { examStatus: 1 }
      ).then((res) => {
        this.loadingTable = false
        console.log('res', res)
        if (res.success) {
          this.tableData = res.data
          this.tableDataPagination.total = res.total
          this.tableDataPagination.pageIndex = res.pageIndex
        }
      })
    },
  },
  mounted() {
    this.sendAsk()
  },
  filters: {
    examStatusFilter(key) {
      const statusMap = {
        1: '草稿',
        2: '等待考试',
        3: '正在考试',
        4: '考试完成',
        5: '等待补考',
        6: '正在补考',
        7: '考试终止',
      }
      return statusMap[key] || `错误的值${key}`
    },
    subjectTypeFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const subjectMap = {
        1: '物理',
        2: '生物',
        3: '化学',
      }
      return val.map((item) => subjectMap[item] || `错误的值${item}`).join(',')
    },
    arrangeModelFilter(key) {
      switch (key) {
        case 1:
          return `定时定点`
        case 2:
          return `随到随考`
        case 3:
          return `定点不定时`
        default:
          return `未选排考方式`
      }
    },
    timeDayFilter(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style scoped>
::v-deep input::-webkit-input-placeholder {
  color: #606266;
}
::v-deep .el-form--inline .el-form-item__content {
  width: 170px !important;
}
</style>
