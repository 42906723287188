<template>
  <div>
    <!-- 条件 -->
    <el-row>
      <el-form :inline="true" :model="params" class="demo-form-inline">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="params.examStatus"
            @change="handleCurrentChange(1)"
            placeholder="全部"
            style="width: 106px"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="等待考试" :value="2"></el-option>
            <el-option label="正在考试" :value="3"></el-option>
            <el-option label="考试完成" :value="4"></el-option>
            <el-option label="考试终止" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阅卷状态" class="formItemBoxStyle">
          <el-select
            v-model="params.markStatus"
            @change="handleCurrentChange(1)"
            placeholder="全部"
            style="width: 106px"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="等待阅卷" :value="1"></el-option>
            <el-option label="正在阅卷" :value="2"></el-option>
            <el-option label="阅卷完成" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补考状态" class="formItemBoxStyle">
          <el-select
            v-model="params.examMakeupStatus"
            @change="handleCurrentChange(1)"
            placeholder="全部"
            style="width: 106px"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="考试完成" :value="4"></el-option>
            <el-option label="等待补考" :value="5"></el-option>
            <el-option label="正在补考" :value="6"></el-option>
            <el-option label="考试终止" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试科目" class="formItemBoxStyle">
          <el-select
            v-model="params.subjectType"
            @change="handleCurrentChange(1)"
            placeholder="全部"
            style="width: 106px"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="物理" :value="1"></el-option>
            <el-option label="生物" :value="2"></el-option>
            <el-option label="化学" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排考方式" class="formItemBoxStyle">
          <el-select
            v-model="params.arrangeModel"
            @change="handleCurrentChange(1)"
            placeholder="全部"
            style="width: 106px"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="定时定点" :value="1"></el-option>
            <el-option label="随到随考" :value="2"></el-option>
            <el-option label="定点不定时" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="params.examName"
            @change="handleCurrentChange(1)"
            style="width: 106px"
          ></el-input>
        </el-form-item>
        <el-form-item label="考试编码" class="formItemBoxStyle">
          <el-input
            v-model="params.examCode"
            @change="handleCurrentChange(1)"
            style="width: 106px"
          ></el-input>
        </el-form-item>
        <el-button v-throttle type="primary" @click="sendAsk">查询</el-button>
        <el-button v-throttle @click="onEmpty">重置</el-button>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      default-expand-all
      row-key="examId"
      :tree-props="{ children: 'children' }"
      v-loading="loadingTable"
    >
      <el-table-column
        align="center"
        prop="examName"
        label="考试名称"
      ></el-table-column>
      <el-table-column align="center" label="考试状态" width="120">
        <template slot-scope="scope">{{
          scope.row.examStatus | examStatusFilter
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="markStatus"
        label="阅卷状态"
        width="120"
      >
        <template slot-scope="scope">{{
          scope.row.markStatus | examMarkStatusFilter
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="examCode"
        label="考试编码"
        width="130"
      ></el-table-column>
      <el-table-column align="center" label="考试科目" width="120">
        <template slot-scope="scope">{{
          scope.row.subjectTypeList | subjectTypeFilter
        }}</template>
      </el-table-column>
      <el-table-column align="center" label="开始时间" width="160">
        <template slot-scope="scope">{{
          scope.row.examStartTime | timeDayFilter
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="arrangeModel"
        label="排考方式"
        width="120"
      >
        <template slot-scope="scope">{{
          scope.row.arrangeModel | arrangeModelFilter
        }}</template>
      </el-table-column>
      <el-table-column align="center" label="考生登录方式" width="110">
        <template slot-scope="scope">
          <el-button
            v-throttle
            type="primary"
            size="mini"
            @click="
              openDialogStudentLoginType(
                scope.row.examId,
                scope.row.examineeLoginType
              )
            "
            >设置</el-button
          >
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="巡考查看" width="110">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="goExamVideoCheck(scope.row.examId)"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="操作" width="400">
        <template slot-scope="scope">
          <el-button
            v-throttle
            type="primary"
            size="mini"
            @click="checkBtn(scope.row)"
            >查看</el-button
          >
          <el-button
            v-throttle
            v-if="!scope.row.makeup || !scope.row.cityExam"
            v-show="!scope.row.cityExam"
            type="primary"
            size="mini"
            @click="copyBtn(scope.row.examId)"
            >复制</el-button
          >
          <!-- <template v-if="!scope.row.makeup">
            <el-button
              v-throttle
              v-if="scope.row.examStatus == 4 && scope.row.needMakeup == true"
              type="warning"
              size="mini"
              @click="
                arrangeBtn(
                  scope.row.mainExamId,
                  scope.row.parentExamId,
                  scope.row.examId
                )
              "
              >安排补考</el-button
            >
          </template> -->
          <el-button
            v-throttle
            v-if="scope.row.examStatus != 7"
            type="success"
            size="mini"
            @click="
              arrangeBtn(
                scope.row.mainExamId,
                scope.row.parentExamId,
                scope.row.examId
              )
            "
            >补考名单</el-button
          >
          <el-button
            v-throttle
            v-if="scope.row.examStatus == 4 || scope.row.examStatus == 7"
            type="danger"
            size="mini"
            @click="deleteBtn(scope.row.examId)"
            >删除</el-button
          >
          <el-button
            v-throttle
            v-if="
              scope.row.examStatus == 2 ||
              scope.row.examStatus == 3 ||
              scope.row.examStatus == 5 ||
              scope.row.examStatus == 6
            "
            type="danger"
            size="mini"
            @click="terminationBtn(scope.row.examId)"
            >终止</el-button
          >
          <el-button
            v-throttle
            v-if="scope.row.examStatus == 3 || scope.row.examStatus == 6"
            type="danger"
            size="mini"
            @click="finishBtn(scope.row.examId)"
            >结束考试</el-button
          >
          <!-- <el-button
            v-throttle
            v-if="scope.row.examStatus == 4 && !scope.row.makeup"
            type="success"
            size="mini"
            @click="downExamMakeupExamineeExcel(scope.row.examId)"
            >补考名单</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
    <!-- 考生登录方式弹框 -->
    <el-dialog
      title="考生登录方式"
      :visible.sync="dialogStudentLoginType"
      center
    >
      <el-row class="middleBox">
        <table border="1">
          <tr>
            <td class="bgColor">登录方式</td>
            <td class="bgColor">状态</td>
            <td class="bgColor" style="width: 400px">备注</td>
          </tr>
          <tr>
            <td>线下审核</td>
            <td>
              <el-switch
                v-model="dialogStudentLoginType"
                active-color="#409eff"
                disabled
              ></el-switch>
            </td>
            <td style="text-align: left">
              线下审核默认为开启状态，不能关闭，由老师在线下对考生进行线下审核是否考生本人。
            </td>
          </tr>
          <tr>
            <td>刷脸登录</td>
            <td>
              <el-switch
                style="display: block"
                v-model="dialogStudentLoginMsg.examineeLoginType"
                active-color="#409eff"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </td>
            <td style="text-align: left; width: 400px">
              刷脸登录默认为关闭状态，若开启刷脸登录，则学生端进入考试需要进行人脸验证，验证通过之后方能进入考试，如有特殊情况，可由学生端发起人工核验，由监考端审核通过或者拒绝，开关的开启和关闭仅对未开始的场次生效。
            </td>
          </tr>
        </table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-throttle
          size="medium"
          type="danger"
          @click="dialogStudentLoginType = false"
          style="margin-right: 30px"
          >取 消</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="examLoginTypeFunc"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  examInfoArrange,
  deleteExamInfo,
  copyExamInfo,
  terminationExam,
  endExam,
  examLoginType,
  getExamineeLoginType,
  downExamMakeupExamineeExcelApi,
} from '@/api/exam/exam.js'
import dayjs from 'dayjs'
import { Loading } from 'element-ui'
export default {
  // 排考
  data() {
    return {
      // 条件
      params: {
        arrangeModel: '',
        examStatus: '',
        subjectType: '',
        markStatus: '',
        examMakeupStatus: 0,
      },
      tableData: [
        // {
        // arrangeModel: 0,
        // examCode: "",
        // examId: 0,
        // examName: "",
        // examStartTime: 0,
        // examStatus: 0,
        // markType: 0,
        // subjectType: []
        // }
      ],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
      // 设置学生登录弹框
      dialogStudentLoginType: false,
      dialogStudentLoginMsg: {
        examId: '',
        examineeLoginType: 1,
      },
      loadingTable: false,
    }
  },
  methods: {
    downExamMakeupExamineeExcel(examId) {
      downExamMakeupExamineeExcelApi(examId).then((res) => {
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$imageAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    // 结束考试
    finishBtn(val) {
      this.$confirm('确认结束考试?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          endExam(val).then((res) => {
            console.log('结束考试', val, res)
            if (res.success) {
              this.sendAsk()
              this.$message({
                type: 'success',
                message: '已结束考试!',
              })
            } else {
              this.$message({
                type: 'info',
                message: res.msg,
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    onEmpty() {
      console.log('清空')
      this.params = {
        arrangeModel: '',
        examStatus: '',
        subjectType: '',
        examMakeupStatus: 0,
      }
      this.sendAsk()
    },
    deleteBtn(val) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteExamInfo(val).then((res) => {
            console.log(' 删除', val, res)
            if (res.success) {
              this.sendAsk()
              this.$emit('flushedFunc')
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    copyBtn(val) {
      this.$confirm('确认复制?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let loadingInstance = Loading.service({
            text: '复制中',
          })
          copyExamInfo(val).then((res) => {
            loadingInstance.close()
            if (res.success) {
              this.sendAsk()
              this.$emit('flushedFunc')
              this.$message({
                type: 'success',
                message: '复制成功!',
              })
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消复制',
          })
        })
    },
    arrangeBtn(mainExamId, parentExamId, examId) {
      console.log('安排补考', mainExamId, parentExamId, examId)
      this.$router.push({
        path: '/exam/examRetake',
        query: {
          examId: examId,
          mainExamId: mainExamId,
          parentExamId: parentExamId,
        },
      })
      // this.$confirm('确认安排补考?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // })
      //   .then(() => {})
      //   .catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消',
      //     })
      //   })
    },
    terminationBtn(val) {
      console.log('终止', val)
      this.$confirm('确认终止?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          terminationExam(val).then((res) => {
            console.log('终止', val, res)
            if (res.success) {
              this.sendAsk()
              this.$message({
                type: 'success',
                message: '终止成功!',
              })
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    checkBtn(val) {
      console.log(' 查看', val)
      this.$router.push({
        path: '/exam/examCheck',
        query: {
          examId: val.examId,
          makeup: val.makeup,
        },
      })
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.sendAsk()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.sendAsk()
    },
    // 请求
    sendAsk() {
      // if (this.params.arrangeModel == '') { delete this.params.arrangeModel }
      // if (this.params.examStatus == '') { delete this.params.examStatus }
      // if (this.params.subjectType == '') { delete this.params.subjectType }
      for (let key in this.params) {
        if (
          this.params[key] == 0 ||
          this.params[key] == '' ||
          this.params[key] == undefined
        ) {
          delete this.params[key]
        }
      }
      this.loadingTable = true
      examInfoArrange(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.params
      ).then((res) => {
        this.loadingTable = false
        if (res.success) {
          this.tableData = res.data
          this.tableDataPagination.total = res.total
        }
        console.log('tableData', this.tableData)
      })
    },
    // 打开考试登录方式弹框
    openDialogStudentLoginType(examId, examineeLoginType) {
      getExamineeLoginType(examId).then((res) => {
        if (res.success) {
          this.dialogStudentLoginType = true
          this.dialogStudentLoginMsg = {
            examId: examId,
            examineeLoginType: res.data.configOption,
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 更新考试考生登录方式
    examLoginTypeFunc() {
      examLoginType(this.dialogStudentLoginMsg)
        .then((res) => {
          if (res.success) {
            this.$message.success('设置成功')
            this.onEmpty()
            // 关闭弹框
            this.dialogStudentLoginType = false
          } else {
            this.$message.info('设置失败')
          }
        })
        .catch((err) => {
          this.$message.info('设置失败')
        })
    },
    goExamVideoCheck(val) {
      this.$router.push({ path: '/exam/examVideoCheck?examId=' + val })
    },
  },
  mounted() {
    this.sendAsk()
  },
  filters: {
    examStatusFilter(key) {
      const statusMap = {
        1: '草稿',
        2: '等待考试',
        3: '正在考试',
        4: '考试完成',
        5: '等待补考',
        6: '正在补考',
        7: '考试终止',
      }
      return statusMap[key] || `错误的值${key}`
    },
    examMarkStatusFilter(key) {
      switch (key) {
        case 1:
          return `等待阅卷`
        case 2:
          return `正在阅卷`
        case 3:
          return `阅卷完成`
        default:
          return `错误的值${key}`
      }
    },
    subjectTypeFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const subjectMap = {
        1: '物理',
        2: '生物',
        3: '化学',
      }
      return val.map((item) => subjectMap[item] || `错误的值${item}`).join(',')
    },
    arrangeModelFilter(key) {
      switch (key) {
        case 1:
          return `定时定点`
        case 2:
          return `随到随考`
        case 3:
          return `定点不定时`
        default:
          return `未选排考方式`
      }
    },
    timeDayFilter(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
    examineeLoginTypeFilter(key) {
      switch (key) {
        case 1:
          return `线下审核`
        case 2:
          return `人脸登录`
        default:
          return `未选排`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-input-placeholder {
  color: #606266;
}
.middleBox {
  table {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
  td {
    padding: 10px;
  }
  .bgColor {
    background: rgba(223, 230, 236, 1);
    // min-width: 200px;
    text-align: center;
  }
}
::v-deep .el-form--inline .el-form-item__content {
  // width: 170px !important;
}
</style>
