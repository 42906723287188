<template>
  <div>
    <!-- 添加考试 -->
    <el-row style="margin-bottom: 20px">
      <router-link to="/exam/examAdd">
        <el-button
          v-throttle
          size="medium"
          type="primary"
          icon="el-icon-circle-plus-outline"
          >添加考试</el-button
        >
      </router-link>
    </el-row>
    <el-row style="margin-bottom: 20px">
      <arrange ref="flushed2" @flushedFunc="flushedFunc" />
    </el-row>
    <el-row>
      <draft ref="flushed" @flushedFunc2="flushedFunc2" />
    </el-row>
  </div>
</template>

<script>
import arrange from './components/arrange.vue'
import draft from './components/draft.vue'
export default {
  // 考试管理
  name: 'examManage',
  components: { arrange, draft },
  data() {
    return {}
  },
  methods: {
    flushedFunc() {
      // 调用清空刷新
      console.log('执行了父级')
      this.$refs.flushed.onEmpty()
    },
    flushedFunc2() {
      // 调用清空刷新
      console.log('执行了父级')
      this.$refs.flushed2.onEmpty()
    },
  },
  mounted() {},
}
</script>

<style>
.el-form-item {
  margin-bottom: 20px;
}
</style>
